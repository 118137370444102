export const data = 
[
	{
		id: 1,
		name: 'Radio: Oral Deportiva',
		anio: 2017,
		description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'vOEKD5jfY-8',
		imgSlider: './media/gallery/deportivo/RRSSstories.jpg',
		imgPreview: ''
    },
	{
		id: 2,
		name: 'Lorem ipsum',
		anio: 2018,
		description: 'Lorem ipsum amet, consectetur adipisicing.',
		isVideo: false,
		idYoutube: '',
		imgSlider: './media/gallery/deportivo/stadium.jpg',
		imgPreview: './media/gallery/deportivo/stadium.jpg',
		fullColor: true,
		dimension: 'vertical'
	},
    {
		id: 3,
		name: 'Lorem ipsum',
		anio: 2018,
		description: 'Lorem ipsum amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'HbEuFNo0xhs',
		imgSlider: './media/gallery/deportivo/Inter-MKT-Deportivocuadrado.jpg',
		imgPreview: ''
    }
	
];