export const data = 
[
	{
		id: 1,
		name: 'Lorem ipsum',
		anio: 2018,
		description: 'Lorem ipsum amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'ZG050eUK--g',
		imgSlider: './media/gallery/medios/reel.png',
		imgPreview: ''
	},
	{
		id: 2,
		name: 'Radio: Oral Deportiva',
		anio: 2017,
		description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: '5ghUmE4lSyE',
		imgSlider: './media/gallery/medios/PokerStars.jpg',
		imgPreview: ''
	},
	{
		id: 2,
		name: 'Radio: Oral Deportiva',
		anio: 2017,
		description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'jivZcJmdfgk',
		imgSlider: './media/gallery/medios/pbestatica.jpg',
		imgPreview: ''
	},
];