export const data = 
[
	{
		id: 1,
		name: 'Lorem ipsum',
		anio: 2018,
		description: 'Lorem ipsum amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'CoACh--ETfE',
		imgSlider: './media/gallery/evento/messi.jpg',
        imgPreview: ''
	},
    {
		id: 2,
		name: 'Lorem ipsum',
		anio: 2018,
		description: 'Lorem ipsum amet, consectetur adipisicing.',
		isVideo: false,
		idYoutube: '',
		imgSlider: './media/gallery/evento/abzurdah.png',
		imgPreview: './media/gallery/evento/abzurdah.png',
		dimension: 'vertical'
    },
    {
		id: 3,
		name: 'Lorem ipsum',
		anio: 2018,
		description: 'Lorem ipsum amet, consectetur adipisicing.',
		isVideo: false,
		idYoutube: '',
		imgSlider: './media/gallery/evento/martin-liberman.jpg',
		imgPreview: './media/gallery/evento/martin-liberman.jpg',
		dimension: 'vertical'
    },
    {
		id: 4,
		name: 'Lorem ipsum',
		anio: 2018,
		description: 'Lorem ipsum amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'Oi4LaCPcKMY',
		imgSlider: './media/gallery/evento/circodusoleil.jpg',
		imgPreview: ''
    }
];