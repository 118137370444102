export const data = 
[
	{
		id: 1,
		name: 'Lorem ipsum',
		anio: 2018,
		description: 'Lorem ipsum amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'aiDKRBFcUqc',
		imgSlider: './media/gallery/esport/esport.jpg',
		imgPreview: ''
	}
	
];