export const data = 
[
	{
		id: 1,
		name: 'Ezzequiel - Reto de los 90 segundos',
		anio: 2017,
		description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'OdAQrzhXzgY',
		imgSlider: './media/gallery/entretenimiento/caratula_ezequiel.png',
		imgPreview: './media/gallery/entretenimiento/caratula_ezequiel.png'
	},
	{
		id: 2,
		name: 'Lorem ipsum',
		anio: 2018,
		description: 'Lorem ipsum amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'fwlBLRPdtQ0',
		imgSlider: './media/gallery/entretenimiento/octibenn-cuadrado.jpg',
		imgPreview: './media/gallery/entretenimiento/octibenn-cuadrado.jpg'
	},
	{
		id: 3,
		name: 'Radio: Oral Deportiva',
		anio: 2017,
		description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
		isVideo: true,
		idYoutube: 'iZEmufVkVRE',
		imgSlider: './media/gallery/entretenimiento/caratula_nueva.png',
		imgPreview: './media/gallery/entretenimiento/caratula_nueva.png'
	}
];